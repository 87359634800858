import React, {useEffect, useState } from 'react';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import OrderFeed from './OrderFeed.js';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: '#005442'
    },
}));

export default function OrderHome({phone} ) {
    const [orderIds, setOrderIds] = useState([])
    
        
    useEffect(() => {
        let url = 'https://conners-curbside.com/wp-json/conners-curbside/v1/get-orders';
        axios.post( url)
        .then( response =>{
            setOrderIds(response.data)
        })
    }, []);
    

    return(
        <React.Fragment>
            <Grid container spacing={3} >
                <OrderFeed orderIds={orderIds} phone={phone}></OrderFeed>
            </Grid> 
        </React.Fragment>
          
                
    )

}