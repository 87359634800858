import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, FormControl, TextField } from '@material-ui/core';
import TestCode from '../Components/TestCode.js';
import GetCode from '../Components/GetCode.js';





const useStyles = makeStyles((theme) => ({
    root: {
        height: '60vh',
        marginTop: '15vh',
        textAlign: 'center'
    },
    form: {
        width: '100%',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
          }
    },
  }));


export default function LoginForm( {setAuth, isAuth, phone, setPhone, codeRequested, setCodeRequested, } ) {
    const classes = useStyles();
    const [userName, setUserName] = useState(' ');

    const handleChangePhone = (e) => {
        setPhone(e.target.value)
    }

    const handleChangeName = (e) => {
        setUserName(e.target.value)
    }
    
    return(
        <Container className={classes.root} >
            <img style={{marginBottom: '50px'}} src="https://conners-curbside.com/wp-content/uploads/2021/06/curbside-logo-03.png" />
            <GetCode 
                phone={phone} 
                userName={userName}
                handlePhone={ handleChangePhone }
                handleName={ handleChangeName }
                codeRequested={codeRequested} 
                setCodeRequested={setCodeRequested}>
            </GetCode>
            <TestCode 
                codeRequested={codeRequested} 
                setCodeRequested={setCodeRequested} 
                userName={userName}
                phone={phone} 
                isAuth={isAuth} 
                setAuth={setAuth}>
            </TestCode>
        </Container>
    );
}