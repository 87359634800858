import React, {useEffect, useState } from 'react';
import axios from 'axios';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlLabel, TextField, FormControl, Checkbox, Grid, Card, CardHeader, CardContent, CardActions, Collapse, Avatar, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import OrderActions from './OrderActions.js';
import CommentField from './CommentField.js'




const useStyles = makeStyles((theme) => ({
    root: {
        background: '#eeeeee',
        position: 'fixed',
        position: '0',
        padding: '5px 30px',
        color: '#333'

    },
    orderTotal: {
        marginTop: '10px',
        padding: '5px 0 10px 20px',
        display: 'flex',
        borderRadius: '5px',
        backgroundColor: '#f5f5f5'
    },
    header: {
        backgroundColor: '#eeeeee',
    },
    headerOther: {
        backgroundColor: '#bdbdbd',
    },
    headernew: {
        backgroundColor: '#4caf4e'
    },
    headerclarifying: {
        backgroundColor: '#ffecb3'
    },
    headershopping: {
        backgroundColor: '#ffa726'
    },
    headerpaymentpending: {
        backgroundColor: '#ef5551'
    },
    headerPaid: {
        backgroundColor: '#a5d6a7'
    },
    headercomplete: {
        backgroundColor: '#ef5350'
    },
    headerLoading: {
        backgroundColor: '#4caf4e'
    },
    menuIcon: {
        fontSize: '2em'
    },
    formControl: {
        width: '100%'
    }
    
}));


export default function OrderDetail( {orderId, phone}) {
const classes = useStyles();
const [orderDetails, setOrderDetails] = useState({});
const [orderDate, setOrderDate] = useState('');
const [orderStatus, setOrderStatus] = useState('');
const [shoppingList, setShoppingList] = useState([]);
const [orderTotal, setOrderTotal] = useState('0.00');
const [comms, setComms] = useState([]);
const [hideComments, setHideComments] = useState(false);
const [expandComments, setExpandComments] = useState(false);
const [hideTotal, setHideTotal] = useState(true);



    const changeOrderStatus = (newStatus) => {
        setOrderStatus(newStatus)
        switch(newStatus){
            case 'wc-shopping':
                setHideComments(true);
                let url="https://conners-curbside.com/wp-json/conners-curbside/v1/begin-shopping-order"
                let body = {
                    status: newStatus,
                    order_id: orderId
                }
                let formdata = new FormData();
                for (const property in body) {
                    formdata.append(property, body[property]);
                }
                axios.post(url, formdata)
                .then( result =>{
                    return result.data
                })
                break;
            case 'cancelled':
                alert("are you sure you want to cancel this order?");
                let cancel="https://conners-curbside.com/wp-json/conners-curbside/v1/cancel-order"
                let cancelBody = {
                    status: newStatus,
                    order_id: orderId
                }
                let cancelData = new FormData();
                for (const property in cancelBody) {
                    cancelData.append(property, cancelBody[property]);
                }
                axios.post(cancel, cancelData)
                .then( result =>{
                    return result.data
                })
                break;
            case 'pending':
                let totalUrl="https://conners-curbside.com/wp-json/conners-curbside/v1/set-order-total"
                alert("Are you sure you have the total ready? This will alert the customer that the order is ready for pickup and send a payment link.");
                console.log(orderTotal);
                setOrderStatus('payment pending')
                let totalBody = {
                    order_total: orderTotal,
                    order_id: orderId
                }
                let totalData = new FormData();
                for (const property in totalBody) {
                    totalData.append(property, totalBody[property]);
                }
                axios.post(totalUrl, totalData)
                .then( result =>{
                    setHideTotal(true)
                    setHideComments(false);
                })
                break;
            default : 
                console.log('error updating status');
        }
        
    }
 
     /* Make API Call for post meta*/
     useEffect(() => {
        let ignore = false;
        async function fetchData() {
            let url = 'https://conners-curbside.com/wp-json/conners-curbside/v1/get-order';
            let body = {
                order_id: orderId,
            }
            let formdata = new FormData();
            for (const property in body) {
                formdata.append(property, body[property]);
            }
            const result = await axios.post(url, formdata, )
            if (!ignore) {
                setOrderDetails(result.data);
                //date
                let createdDate = result.data.order_created.date;
                let relative = formatRelative( new Date(createdDate), new Date() );
                if(relative.includes("today") || relative.includes("yesterday")){
                    let orderDate =  relative;
                    setOrderDate(orderDate)
                } else {
                    let theDate = format( new Date(createdDate), ' LL/dd/yyyy h:mm a');
                    let orderDate = theDate;
                    setOrderDate(orderDate)
                }
                //shopping list
                setShoppingList(result.data.shopping_list);
                setComms(result.data.comms);

                //status avatar
                switch(result.data.order_status){
                    case 'pending':
                        setOrderStatus('payment pending');
                        break;
                    case 'processing':
                        setOrderStatus('paid (waiting pickup)');
                        break;
                    case 'loading':
                        setOrderStatus('loading (they\'re here)');
                        break;
                    default:
                        setOrderStatus(result.data.order_status);
                }
            }
        }
        fetchData();
        return () => { ignore = true; }
    }, []);


    const getAvatar = (theStatus) => {
        switch(theStatus){
            case 'new':
                return (
                    <Avatar className={classes.headernew}>N</Avatar>
                )
                break;
            case 'clarifying':
                return (
                    <Avatar className={classes.headerclarifying}>S</Avatar>
                )
                break;
            case 'shopping':
                return (
                    <Avatar className={classes.headershopping}>S</Avatar>
                )
                break;
            case 'paid (waiting pickup)':
                return (
                    <Avatar className={classes.headerPaid}>R</Avatar>
                )
                break;
            case 'loading (they\'re here)':
                return (
                    <Avatar className={classes.headerLoading}>L</Avatar>
                )
                break;
            case 'payment pending':
                return (
                    <Avatar className={classes.headerpaymentpending}>PP</Avatar>
                )
            default:
            return (
                <Avatar className={classes.headerOther}>o</Avatar>
            )
        }
    }

    const refreshDetails = (e) => {
        let url = 'https://conners-curbside.com/wp-json/conners-curbside/v1/get-order';
        let body = {
            order_id: orderId,
        }
        let formdata = new FormData();
        for (const property in body) {
            formdata.append(property, body[property]);
        }
        axios.post(url, formdata)
        .then( result =>{
            setOrderDetails(result.data);
            //date
            let createdDate = result.data.order_created.date;
            let relative = formatRelative( new Date(createdDate), new Date() );
            if(relative.includes("today") || relative.includes("yesterday")){
                let orderDate =  relative;
                setOrderDate(orderDate)
            } else {
                let theDate = format( new Date(createdDate), ' LL/dd/yyyy h:mm a');
                let orderDate = theDate;
                setOrderDate(orderDate)
            }
            //shopping list
            setShoppingList(result.data.shopping_list);
            setComms(result.data.comms);
            //status avatar
            //status avatar
            //status avatar
            switch(result.data.order_status){
                case 'pending':
                    setOrderStatus('payment pending');
                    break;
                case 'processing':
                    setOrderStatus('paid (waiting pickup)');
                    break;
                case 'loading':
                    setOrderStatus('loading (they\'re here)');
                    break;
                default:
                    setOrderStatus(result.data.order_status);
            }
        })
    }

    const getCheckBox = (item, index) => {
        if(item.trim() === 'Curbside' && index === 0){
            return null;
        }
        if(item.includes(', ')){
            const itemSegments = item.split(', ');
            return (
                itemSegments.map( (segment, segmentIndex) =>{
                    return(
                        <FormControl className={classes.formControl} key={`form-control-${segment}-${segmentIndex}`}>
                            <FormControlLabel key={`segment-${segmentIndex}`} control={<Checkbox key={`checkbox-${index}-${segmentIndex}`} name={`check-${segmentIndex}-${segment}`} />} label={segment} />
                        </FormControl>
                    )
                })
            )
        } else {
            return(
                <FormControl className={classes.formControl} key={`item-form-control-${index}`}>
                    <FormControlLabel key={`item-${index}`} control={<Checkbox name={`check-${item}`} />} label={item} />
                </FormControl>
            )
        }
    }

    const toggleComments = () => {
        setExpandComments(!expandComments);
    }

    //when click dollar sign
    const showTotal = (e) => {
        setHideComments(true);
        setHideTotal(!hideTotal);
    }

    const handleChangeTotal = (e) => {
        setOrderTotal(e.target.value)
    }


   
    if(orderDetails === {}){
        return null;
    } else {
        return(
            <Grid key={`grid-item-${orderId}`} item xs={12} sm={6} md={4}>
                <Card key={`card-${orderId}`}>
                    <CardHeader
                        className={classes.header}
                        avatar={getAvatar(orderStatus)}
                        title={`Order #${orderDetails.order_id} ${orderStatus}`}
                        subheader={`${orderDetails.customer_name} - ${orderDate}`}
                        action={<IconButton aria-label="settings" onClick={(e) => refreshDetails(e)}>
                                        <FontAwesomeIcon icon="sync"></FontAwesomeIcon>
                                </IconButton>
                                }
                    >
                    </CardHeader>
                    <CardContent>
                        <Typography variant="subtitle2">Shopping List Messages</Typography>
                    
                        {shoppingList.map ( (item, index) => {
                            return getCheckBox(item, index)
                        })}
                        {(comms.length >= 1)
                            ? <React.Fragment>
                                <Typography key={`comms-title-${orderId}`} variant="subtitle2">Clarifications</Typography>
                                {comms.map ( (comm, index) => {
                                    return <p key={`comms-${index}`}>{comm}</p>
                                })}
                            </React.Fragment>
                            : null
                        }
                        {(hideTotal === false)
                            ? <div className={classes.orderTotal}>
                                <CurrencyTextField 
                                    currencySymbol="$"
                                    outputFormat="string"
                                    fullWidth
                                    id={`total_price${orderId}`} 
                                    value={orderTotal} 
                                    label="Order Total"
                                    onChange={handleChangeTotal}
                                    />
                                <IconButton onClick={(e) => changeOrderStatus('pending')}>
                                    <FontAwesomeIcon icon="paper-plane"></FontAwesomeIcon>
                                </IconButton>
                            </div>
                            : null
                        }
                        {(hideComments === true)
                            ? null
                            : <CommentField hideComments={hideComments} orderId={orderId} refreshDetails={refreshDetails} ></CommentField>
                        }
                    </CardContent>
                    
                    <OrderActions
                        orderId={orderId}
                        refreshDetails={refreshDetails}
                        changeOrderStatus={changeOrderStatus}
                        showTotal={showTotal}
                        setOrderTotal={setOrderTotal}
                    ></OrderActions>
                </Card>
            </Grid>
        )
    }
    
        
}