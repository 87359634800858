import React, {useState} from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, TextField, TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';




const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',
        padding: '5px 0 10px 20px',
        display: 'flex',
        borderRadius: '5px',
        backgroundColor: '#f5f5f5'
    }, 
    holdInput:{
        marginTop: '10px',
        width: '88%'
    }
}));


export default function CommentField( { orderId, refreshDetails }) {
    const classes = useStyles();
    const [newComment, setNewComment] = useState('');

    const handleCommentChange = (e) =>{
        setNewComment(e.target.value)
    }


    const sendQuestion = (e) => {
        let body = {
            order_id: orderId,
            question: newComment
        }
        let url = 'https://conners-curbside.com/wp-json/conners-curbside/v1/send-question';
        let formdata = new FormData();
        for (const property in body) {
            formdata.append(property, body[property]);
        }
        axios.post(url, formdata)
        .then( response =>{
            refreshDetails('');
            document.getElementById(`comment-field-${orderId}`).value = '';
        })
    }

    return (
        <form className={classes.root}>
            <div className={classes.holdInput}>
                <TextareaAutosize 
                    key={`comment-field-${orderId}`}
                    id={`comment-field-${orderId}`}
                    aria-label="ask questions" 
                    onChange={ (e) =>handleCommentChange(e) }
                    style={{width: '95%'}} 
                    rowsMin={3} 
                    placeholder="ask questions, get answers"
                     />
            </div>
            <IconButton 
                style={{maxWidth: '70px'}}
                onClick={ (e) => sendQuestion(e)}
                
                >
                <FontAwesomeIcon icon="paper-plane"></FontAwesomeIcon>
            </IconButton>
        </form>
    )

}