import React from 'react';
import axios from 'axios';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import OrderDetail from './OrderDetail.js';


export default function OrderFeed( {orderIds, phone} ) {
    
    return(
        <React.Fragment>
            {orderIds.map( orderId =>{
                    return(
                        <OrderDetail key={orderId} orderId={orderId} phone={phone}></OrderDetail>
                    )
                })
            }
        </React.Fragment>
    ) 
}