import React, {useContext, useState, useEffect} from 'react';
import { Context } from '../../Context/Context.js';
import {useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {Container, Box, AppBar, Toolbar, IconButton, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderHome from '../Components/OrderHome.js';
import LoginForm from '../Components/LoginForm.js'




const useStyles = makeStyles(() => ({
    root: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#ffffff',
        padding: '15px',
        overflowY: 'scroll'
    },
    appBar: {
        backgroundColor: '#005442'
    },
    form: {
        width: '100%',
    },
  }));


export default function Orders( ) {
    const [isAuth, setIsAuth] = useState('');
    const {phone} = useParams();
    const [userPhone, setUserPhone] = useState(phone);
    const [codeRequested, setCodeRequested] = useState('initial');
    const classes = useStyles();

    useEffect(() => {
        if(userPhone === undefined){
            setUserPhone(' ')
        }
    }, []);

    const setPhone = (newPhone) => {
        setUserPhone(newPhone);
    }
    

    const setAuth = (result) => {
        if(result.auth === true){
            setIsAuth('yes');
            localStorage.setItem('conners-curbside-admin-auth', result.auth);
        } else {
            setIsAuth('no');
        }
    }

    //use effect to get the registrations assigned to this billing phone number
    //show
    /* Make API Call for post meta*/
    useEffect(() => {
        let localAuth = JSON.parse(localStorage.getItem('conners-curbside-admin-auth'));
        if(localAuth){
            if(localAuth === true){
            setIsAuth('yes');
            }
        }
    }, []);

    const signOut = () => {
        localStorage.removeItem('conners-curbside-admin-auth');
        window.location.reload();
    }

    


    

    return(
        <Container
            display="flex"
            className={classes.root}
        >
            {(isAuth === 'yes')
                ? <React.Fragment>
                    <AppBar className={classes.appBar}>
                        <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                            <img 
                                alt="Conners Curbside" 
                                style={{maxHeight: '50px'}}
                                src="https://conners-curbside.com/wp-content/uploads/2021/06/curbside-logo-03.png" />
                            {(isAuth)
                                ?  <IconButton 
                                        onClick={ () => signOut() }
                                        style={{color: '#ffffff'}}
                                        >
                                            <FontAwesomeIcon icon="sign-out-alt" ></FontAwesomeIcon>
                                    </IconButton>
                                : null
                            }
                        </Toolbar>
                    </AppBar>
                    <Toolbar></Toolbar>
                    <OrderHome phone={userPhone}></OrderHome>
                </React.Fragment>
                : <LoginForm phone={userPhone} setPhone={setPhone} isAuth={isAuth} setAuth={setAuth} codeRequested={codeRequested} setCodeRequested={setCodeRequested}></LoginForm>
            }
        </Container>
    );
}