import React, {useContext, useState, useEffect} from 'react';
import { Context } from '../../Context/Context.js';
import {useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {Container, Button, Typography, AppBar, Toolbar, Box } from '@material-ui/core';
import LoginForm from '../Components/LoginForm.js';
import SingleOrder from '../Components/SingleOrder.js';



const useStyles = makeStyles(() => ({
    root: {
        width: '100vw',
        height: '100vh'
    },
    form: {
        width: '100%',
        textAlign: 'center',
    },
  }));


export default function Order( ) {
    const [isAuth, setIsAuth] = useState('');
    const [codeRequested, setCodeRequested] = useState('initial');
    const classes = useStyles();
    const {phone} = useParams();
    const {orderId} = useParams();

    

    const setAuth = (result) => {
        if(result.auth === true){
            setIsAuth('yes');
            localStorage.setItem('conners-curbside-admin-auth', result.auth);
        } else {
            setIsAuth('no');
        }
    }

    //use effect to get the registrations assigned to this billing phone number
    //show
    /* Make API Call for post meta*/
    useEffect(() => {
        let localAuth = JSON.parse(localStorage.getItem('conners-curbside-admin-auth'));
        if(localAuth){
            if(localAuth === true){
            setIsAuth('yes');
            }
        }
    }, []);

    return(
        <Container
            display="flex"
            className={classes.root}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <img src="https://conners-curbside.com/wp-content/uploads/2021/06/cropped-curbside-logo-03.png" />
                    {(isAuth)
                        ?  <Button color="inherit">Logout</Button>
                        : null
                    }
                </Toolbar>
            </AppBar>
            <Toolbar></Toolbar>
            <Box>
                
            {(isAuth === 'no')
                ?<React.Fragment>
                    <Typography style={{textAlign: 'center'}} variant="h5">Uh Oh! That secret code didn't match.</Typography>
                    <Typography variant="h5">Please click to try again</Typography>
                    <Button onClick={window.location.reload()}>Try Again</Button>
                </React.Fragment>
                : null
            }
            {(isAuth === 'yes')
                ? <SingleOrder phone={phone} orderId={orderId}></SingleOrder>
                : <LoginForm phone={phone} isAuth={isAuth} setIsAuth={setIsAuth} codeRequested={codeRequested} setCodeRequested={setCodeRequested}></LoginForm>
            } 
            </Box>
        </Container>
    );
}