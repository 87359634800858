import React, {useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, AppBar, Toolbar, IconButton, Button, Typography, Grid, capitalize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';




const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textTransform: "capitalize"
    },
  }));


export default function SingleOrder( {orderId, phone}) {
const classes = useStyles();
const [orderDetails, setOrderDetails] = useState({});


     /* Make API Call for post meta*/
     useEffect(() => {
        let ignore = false;
        async function fetchData() {
            let url = 'https://conners-curbside.com/wp-json/conners-curbside/v1/get-order';
            let body = {
                order_id: orderId,
            }
            let formdata = new FormData();
            for (const property in body) {
                formdata.append(property, body[property]);
            }
            const result = await axios.post(url, formdata, )
            if (!ignore) setOrderDetails(result.data);
        }
        fetchData();
        return () => { ignore = true; }
    }, []);

    

    const status = (order_status) =>{
        if(order_status === 'pending'){
            return 'payment pending'
        }
    }

    const phoneDisplay = (number) => {
        return (
            <a href={'tel:+1'+number}>{number}</a>
        )
    }

    const close = (phone) => {
        window.location.href = "/"+phone
    }
    

    if(orderDetails === {}){
        return null;
    } else {
        return(
            <Container>
                <AppBar color="primary">
                <Toolbar>
                    <IconButton 
                        edge="start" 
                        className={classes.menuButton} 
                        color="inherit" 
                        aria-label="close"
                        onClick={ () => close(phone)}
                        >
                        <FontAwesomeIcon 
                            icon="times" 
                            ></FontAwesomeIcon>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                    Order# {orderDetails.order_id}
                    </Typography>
                    <Typography variant="h6" className={classes.title}>
                    {status(orderDetails.order_status)}
                    </Typography>
                </Toolbar>

                    
                </AppBar>
                

                
            </Container>
        )
    }
    
        
}