import React, {useEffect, useState } from 'react';
import axios from 'axios';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlLabel, FormControl, Checkbox, Grid, Card, CardHeader, CardContent, CardActions, Collapse, Avatar, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', 
        justifyContent:'space-between',
    } 
}));


export default function OrderActions( {showTotal, refreshDetails, orderId, changeOrderStatus, hideCommenting}) {
const classes = useStyles();

const rejectOrder = () => {
    changeOrderStatus('cancelled');
}
const startShopping= () => {
    changeOrderStatus('wc-shopping');
}

    return(
        <CardActions className={classes.root} disableSpacing>
            <IconButton 
                onClick={(e) =>refreshDetails(orderId)}
                aria-label="refresh-order">
                <FontAwesomeIcon  icon="sync"></FontAwesomeIcon>
            </IconButton>
            <IconButton 
                onClick={(e) =>startShopping(e)}
                aria-label="start-shopping">
                <FontAwesomeIcon  icon="shopping-cart"></FontAwesomeIcon>
            </IconButton>
            <IconButton 
                onClick={(e) => showTotal(e)}
                aria-label="set-total">
                <FontAwesomeIcon  icon="dollar-sign"></FontAwesomeIcon>
            </IconButton>
            <IconButton 
                aria-label="reject-order"
                onClick={() =>rejectOrder()}
                >
                <FontAwesomeIcon  icon="trash-alt"></FontAwesomeIcon>
            </IconButton>
        </CardActions>
    )
}
    
        
