import React, {useContext, useState, useEffect} from 'react';
import { Context } from '../../Context/Context.js';
import axios from 'axios';
import {useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {Container, TextField, Button, Typography } from '@material-ui/core';




const useStyles = makeStyles(() => ({
    root: {
        width: '100vw',
        height: '100vh'
    },
    form: {
        width: '100%',
        textAlign: 'center',
        marginTop: '30px'
    },
  }));


export default function TestCode( {userName, codeRequested, setCodeRequested, phone, isAuth, setAuth} ) {
    const [state, setContext] = useContext(Context);
    const [secret, setSecret] = useState('')
    const [appLocation, setAppLocation] = useState('home');
    const [profile, setProfile] = useState({});
    const [WPUser, setWPUser] = useState({});
    const [loginError, setLoginError] = useState();
    const classes = useStyles();

    
    const changeAppLocation = (newLocation) => {
        console.log('clicked '+ appLocation);
        setAppLocation(newLocation);
    }

    const setLocalProfile = (newProfile) => {
        setProfile(newProfile);
    }

    const onEnterCode = (e) => {
        let code = e.target.value;
        setSecret(code);
    }

    const checkCode = () => {
        
            async function testSecret() {
            let url = 'https://conners-curbside.com/wp-json/conners-curbside/v1/check-magic-admin-code';
            let body = {
                name: userName,
                phone: phone,
                token: secret
            }
            let formdata = new FormData();
            for (const property in body) {
                formdata.append(property, body[property]);
            }
            const result = await axios.post(url, formdata, )
            if(result.data.auth === true){
                let CSC_auth = {
                    validated: 'yes',
                    user_id: '',
                    phone: phone
                }
                localStorage.setItem('curbside-admin-auth', JSON.stringify(CSC_auth));
                setAuth(result.data);
            } else {
                setLoginError('Uh Oh! The code did not match. Try again or request a new code.')
            }
            };
            testSecret();
    }

    const displayError = (loginError) => {
        if(loginError){
            return (
                <React.Fragment>
                    <Typography>{loginError}</Typography>
                    <Button
                        style={{marginBottom: '40px'}}
                        color="primary"
                        onClick={ () => window.location.href = '/' }
                    > get new passcode
                    </Button>
                </React.Fragment>
            )
        } else {
            return null
        }  
    }

    if(codeRequested === 'waiting for code' && isAuth !== 'yes'){
        return(
            <Container>
                <form className={classes.form} autoComplete="off">
                    <input type="hidden" value={phone} />
                    {displayError(loginError)}
                    <TextField 
                        id="secret-code" 
                        variant="filled" 
                        label="secret code"
                        fullWidth
                        required
                        onChange={ (e) => onEnterCode(e) }
                        style={{marginBottom: '20px', width: '90%'}}
                        ></TextField>
                    <p>
                        <Button 
                            variant="contained" 
                            color="secondary"
                            onClick={ (e) => (checkCode(e))}
                            >
                            Login
                        </Button>
                    </p>
                 </form>
            </Container>      
        );
    } else {
        return null;
    }

    
}