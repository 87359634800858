import React, {useContext, useState, useEffect} from 'react';
import { Context } from '../../Context/Context.js';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Typography, TextField } from '@material-ui/core';




const useStyles = makeStyles(() => ({
    root: {
        width: '100vw',
        height: '100vh',
        textAlign: 'left'
    },
    form: {
        width: '100%',
        textAlign: 'center',
        marginTop: '30vh'
    },
  }));


export default function GetCode( { handlePhone, handleName, userName, phone, codeRequested, setCodeRequested }) {
    const [state, setContext] = useContext(Context);
    const [secret, setSecret] = useState('')
    const [appLocation, setAppLocation] = useState('home');
    const [profile, setProfile] = useState({});
    const [WPUser, setWPUser] = useState({});
    const [auth, setAuth] = useState({})
    const classes = useStyles();

    
    const changeAppLocation = (newLocation) => {
        console.log('clicked '+ appLocation);
        setAppLocation(newLocation);
    }

    const setLocalProfile = (newProfile) => {
        setProfile(newProfile);
    }

    const getCode = () => {
        async function testSecret() {
        let url = 'https://conners-curbside.com/wp-json/conners-curbside/v1/send-magic-admin-code';
        let body = {
            phone: phone,
            userName: userName
        }
        let formdata = new FormData();
        for (const property in body) {
            formdata.append(property, body[property]);
        }
        const result = await axios.post(url, formdata, )
         if(result.data.code === 'sent'){
            setCodeRequested('waiting for code');
         } else {
             setCodeRequested('error');
         }
        };
        testSecret();
    }

    return(
        <React.Fragment>
            {(codeRequested === 'initial')
                ? <React.Fragment>
                    <form  autoComplete="off" >
                        <TextField 
                            label="your name" 
                            variant="outlined" 
                            id="userName" 
                            name="userName"
                            required
                            value={userName}
                            margin="normal"
                            fullWidth
                            onChange={ handleName }
                            />
                    
                        <TextField 
                            label="phone number" 
                            variant="outlined" 
                            type="tel" 
                            id="phone" 
                            name="phone"
                            required
                            value={phone}
                            margin="normal"
                            fullWidth
                            onChange={ handlePhone }
                            />
                    </form>
                     <Button 
                    type="submit"
                    variant="contained" 
                    color="secondary"
                    onClick={ (e) => getCode(e) }
                    style={{marginTop: '30px'}}
                    >
                    Get A Passcode
                    </Button>
                </React.Fragment>
                : null
            }
        </React.Fragment>
    );
}