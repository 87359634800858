import React, { useState, useEffect } from 'react';
import axios from 'axios';


export const Context = React.createContext();

export function ContextController({children}){

    const objsEqual = (a, b) => {
        delete a.key;
        delete b.key;
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);
        if (aProps.length != bProps.length) {
            return false;
        }
        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
        return true;
    }

    
    let profile;
    // Initial state
    let initialState = {
        registrations: null,
        facebookProfile: '',
        googleApisKey: '',
        appLocation: '',
        wpUsername: '',
        wpApplicationPassword: '',
        posts: [],
        marketLocations: [],
        objsEqual
     }

     //set the initial state into a use State
     const [state, setContext] = useState(initialState);
     
     useEffect ( () => {
        
        
        //go out to the api
        const promises = [];
        
        //MEGDO: need to loop through paginated results as this thing grows...here we are only getting the first page. which is BAD
        //promises.push(axios.get('https://hatterassailing.org/wp-json/community-sailing/v1/registrations-this-season?search=spring'));
        

       
       
    
        Promise.all(promises).then( res => {
            

            
            setContext( {
                appLocation: 'home',
                //registrations: res[0].data.registration_objects
            })

        });

     }, []);
     

    return(
        <Context.Provider value={[state, setContext]}>{children}</Context.Provider>
    )

}