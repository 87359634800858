import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSync, faSignOutAlt, faShoppingCart, faTrashAlt, faComment, faDollarSign, faPaperPlane, faEllipsisV, faTimes } from '@fortawesome/free-solid-svg-icons';
import {  ThemeProvider, createMuiTheme, makeStyles, responsiveFontSizes } from '@material-ui/core/styles';
import { teal, cyan} from '@material-ui/core/colors';
import { CssBaseline, Container } from '@material-ui/core';
import {  ContextController } from './Context/Context.js'
import './App.css';
import Orders from './Components/Pages/Orders.js';
import Order from './Components/Pages/Order.js';




library.add( faSync, faSignOutAlt, faShoppingCart, faTrashAlt, faComment, faDollarSign, faPaperPlane, faEllipsisV, faTimes );


let theme = createMuiTheme({
  typography: {
    fontFamily: 'Quicksand, Arial',
    fontWeight: '500'
    
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Quicksand',
      },
    },
    
  },
  palette: {
    primary: teal,
    secondary: cyan,
  },
  
});

theme = responsiveFontSizes(theme);

const useStyles = makeStyles(() => ({
  root: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    margin: '0',
    padding: '0',
  }
}));

function App() {
  const classes = useStyles();


  return (
      <ContextController>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container  
            maxWidth={false}
            className={classes.root}
            >
            <Router>
                <Switch>
                    <Route exact path="/" component={Orders} />
                    <Route exact path="/:phone" component={Orders} />
                    <Route exact path="/:phone/:orderId" component={Order} />
                  </Switch>
            </Router>
          </Container>
        </ThemeProvider>
      </ContextController>    
  )
}

export default App;
